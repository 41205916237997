import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { routes } from './app-routing.routes';
import { AppComponent } from './app.component';
import { AuthService as HttpInterceptorAuthService } from './core/services/http-interceptor/dependencies/auth.service';
import { HttpInterceptorModule } from './core/services/http-interceptor/http-interceptor.module';
import { locales as toastLocales } from './core/services/toast/i18n/all';
import { CoreTranslationService } from './core/services/translation/translation.service';
import { ThemeService } from './core/services/theme/theme.service';
import { HeaderComponent } from './common/header/header.component';
import { LayoutComponent } from './common/layout/layout.component';
import { FooterComponent } from './common/footer/footer.component';

@NgModule({
  declarations: [AppComponent, LayoutComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    RouterModule.forRoot(routes, { enableTracing: false }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpInterceptorModule,
  ],
  providers: [
    {
      provide: HttpInterceptorAuthService,
      useValue: {
        logout: () => undefined,
        getAccessToken: () => 'fake-token',
      },
    },
  ],
  exports: [HeaderComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private readonly coreTranlationService: CoreTranslationService,
    private readonly translateService: TranslateService,
    private readonly themeService: ThemeService
  ) {
    this.translateService.addLangs(['en', 'pt', 'es']);
    this.translateService.setDefaultLang('pt');
    this.translateService.use('pt');
    this.coreTranlationService.translate(...toastLocales);
    const savedThemeOption = this.themeService.getIsDarkThemeFromLocalStorage();
    this.themeService.enableDarkTheme(savedThemeOption);
  }
}
