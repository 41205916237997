export const locale = {
  lang: 'pt',
  data: {
    AUTH: {
      ERROR: 'E-mail e/ou senha incorretos',
      UNAUTHORIZED: 'Você não possui permissão para acessar esta página.',
      EXPIRED_ACCESS: 'Seu acesso expirou. Por favor, faça log in novamente.',
      LIMIT_EXCEEDED: `Limite de tentativas excedida, tente novamente em {{ dynamicMessage }} segundos.`,
    },
    RESET_PASSWORD: {
      SUCCESS: 'E-mail de troca de senha enviado com sucesso.',
      ERROR: 'Ops, aconteceu um erro! Tente novamente.',
    },
    PAYLY_ACCREDITATION: {
      REGISTER_SUCCESSFULL: 'Cadastro feito com sucesso.',
      REGISTER_WARNING_PAYLY: 'Empresa cadastrada com sucesso na Payly. Verifique as informações de vinculo no Shell Box Empresas!',
      DOCUMENT_ERROR: 'CNPJ Não é o mesmo do cadastro.',
      REGISTER_ERROR_PAYLYL: 'Erro no cadastro na Payly.',
      REGISTER_ERROR: 'Ocorreu um erro. Tente novamente mais tarde.',
      FIELDS_INVALID_ERROR: 'Campos invalidos, por favor verificar!',
    },
  },
};
