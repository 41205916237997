<footer class="main-footer">
  <hr class="border-top" />
  <div class="footer-content">
    <p class="footer-text">
      <b>Fale com a gente por WhatsApp:</b> (019) 3401-2320 | <b>Telefone:</b> 0800 307 00 55. Atendimento de segunda à sexta das 7h às 22h, sábado
      das 7h às 18h e domingo das 08h às 16h ou <b>por e-mail: </b> <a href="mailto:{{ email }}">{{ email }}</a> <br />A marca Shell é licenciada à
      Raízen Combustíveis S/A.
    </p>
  </div>
</footer>
