import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  lang: string;
  data: Record<string, unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class CoreTranslationService {
  constructor(private _translateService: TranslateService) {}

  translate(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      this._translateService.setTranslation(locale.lang, locale.data, true);
    });
  }
}
