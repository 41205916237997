export const locale = {
  lang: 'en',
  data: {
    AUTH: {
      ERROR: 'Incorrect email and/or password',
      UNAUTHORIZED: 'You do not have permission to access this page.',
      EXPIRED_ACCESS: 'Your access has expired. Please, sign in again.',
      LIMIT_EXCEEDED: `Retry limit exceeded, try again in {{ dynamicMessage }} seconds.`,
    },
    RESET_PASSWORD: {
      SUCCESS: 'Successful Password Exchange Email',
      ERROR: 'Oops, an error happened! Try again.',
    },
    PAYLY_ACCREDITATION: {
      REGISTER_SUCCESFULL: 'Registration made successfully.',
      REGISTER_WARNING_PAYLY: 'Company successfully registered with Payly. Check the bond information on shell.',
      DOCUMENT_ERROR: 'CNPJ is not the same as the registration.',
      REGISTER_ERROR_PAYLYL: 'Error in Payly registration.',
      REGISTER_ERROR: 'An error has occurred. Try again later.',
      FIELDS_INVALID_ERROR: 'Invalid fields, please check!',
    },
  },
};
