export const locale = {
  lang: 'es',
  data: {
    AUTH: {
      ERROR: 'Email y/o contraseña incorrectos',
      UNAUTHORIZED: 'No tienes permisos para acceder a esta página.',
      EXPIRED_ACCESS: 'Su acceso ha caducado. Por favor, inicie sesión de nuevo.',
      LIMIT_EXCEEDED: `Se excedió el límite de reintentos, vuelva a intentarlo en {{ dynamicMessage }} segundos.`,
    },
    RESET_PASSWORD: {
      SUCCESS: 'Correo electrónico por contraseña enviada correctamente',
      ERROR: 'Ups, un error sucedió! Inténtalo de nuevo.',
    },
    PAYLY_ACCREDITATION: {
      REGISTER_SUCCESFULL: 'Registro realizado con éxito',
      REGISTER_WARNING_PAYLY: 'Compañía registrada con éxito en Payly. Verifique la información de los bonos en Shell',
      DOCUMENT_ERROR: 'CNPJ no es lo mismo que el registro',
      REGISTER_ERROR_PAYLYL: 'Error en el registro de pago',
      REGISTER_ERROR: 'Ocurrio un error. Inténtalo de nuevo más tarde',
      FIELDS_INVALID_ERROR: 'Campos inválidos, por favor verifique',
    },
  },
};
