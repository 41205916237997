import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '@app/core/services/toast/toast.service';
import { AuthService } from './dependencies/auth.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private readonly toastService: ToastService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              this.authService.logout();
              this.toastService.showToast('AUTH.EXPIRED_ACCESS', 'error');
              break;

            case 403:
              this.router.navigate(['/not-found']);
              this.toastService.showToast('AUTH.UNAUTHORIZED', 'error');
              break;

            default:
          }
        }

        return throwError(() => error);
      })
    );
  }
}
