import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './dependencies/auth.service';

@Injectable()
export class HTTPInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getAccessToken();

    request = request.clone({
      setHeaders: {
        ['Authorization']: `Bearer ${token}`,
      },
    });

    return next.handle(request);
  }
}
