import { HTTPInterceptorService } from './http-interceptor.service';

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptorService } from './error-interceptor.service';

@NgModule({
  providers: [
    HTTPInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
  ],
})
export class HttpInterceptorModule {}
