import { Route } from '@angular/router';
import { LayoutComponent } from './common/layout/layout.component';

export const routes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '**',
        loadChildren: () => import('./modules/not-found/not-found.module').then((m) => m.NotFoundModule),
      },
    ],
  },
];
