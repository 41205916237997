<header class="app-header">
  <div class="app-header__logo">
    <img
      src="/assets/images/shell-logo-login.png"
      alt="Shell logo"
    />
  </div>
  <div class="app-header__display-lead">
    <i class="far fa-user"></i>
    <span>{{ leadName }}</span>
  </div>
</header>
