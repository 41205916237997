import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export type ToastType = 'success' | 'error' | 'info' | 'warning';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService, private translate: TranslateService) {}

  showToast(
    message: string,
    type: ToastType | 'other',
    durationInSeconds = 3,
    position?: 'bottom' | 'top',
    panelClass?: string[],
    _dynamicMessage?: string | number
  ): void {
    const className = `toast-${type === 'other' ? 'info' : type} ngx-toastr`;
    const positionClass = `toast-${position ?? 'top'}-right`;

    this.translate.get(message).subscribe((translatedMessage) => {
      this.toastr.show(translatedMessage, undefined, {
        positionClass: positionClass,
        timeOut: durationInSeconds ? durationInSeconds * 1000 : undefined,
        toastClass: panelClass ? [...panelClass, className].join(' ') : className,
      });
    });
  }
}
